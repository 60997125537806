import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cabb534"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-tree-index-title-name" }
const _hoisted_2 = ["id", "aria-label"]
const _hoisted_3 = { class: "item-tree-index-title-name" }
const _hoisted_4 = { class: "item-tree-index-contents-count has-text-normal" }
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingView = _resolveComponent("LoadingView")!
  const _component_CloserController = _resolveComponent("CloserController")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ItemTree = _resolveComponent("ItemTree")!
  const _component_ContentsBundleIndex = _resolveComponent("ContentsBundleIndex")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(["item-tree-index", `children-${_ctx.n}`]),
    key: _ctx.item.pid
  }, {
    default: _withCtx(() => [
      (_ctx.isProcessing)
        ? (_openBlock(), _createBlock(_component_LoadingView, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["item-tree-index-title level-left is-mobile", { current: _ctx.item.pid === `info:ndljp/pid/${_ctx.route.params.pid}` }])
          }, [
            (_ctx.hasIndices || _ctx.hasContent || _ctx.item.count)
              ? (_openBlock(), _createBlock(_component_CloserController, {
                  key: 0,
                  modelValue: _ctx.state.show,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.show) = $event)),
                  icons: ['accordion-open', 'arrow-right'],
                  "aria-controls": _ctx.ariaControlsTargetId,
                  "aria-labelledby": _ctx.ariaLabelledbyTargetId,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expand(_ctx.item))),
                  onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.expand(_ctx.item)), ["prevent"]), ["space","enter"]))
                }, null, 8, ["modelValue", "aria-controls", "aria-labelledby"]))
              : _createCommentVNode("", true),
            (_ctx.item.pid)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: {
          path: `/${_ctx.makeUpdatedPidString(_ctx.item.pid)}`,
          query: _ctx.$route.query
        },
                  id: _ctx.ariaLabelledbyTargetId,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.state.show = !_ctx.state.show)),
                  onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (_ctx.state.show = !_ctx.state.show), ["prevent"]), ["space","enter"])),
                  replace: !_ctx.isModifyPid(`/${_ctx.makeUpdatedPidString(_ctx.item.pid)}`),
                  class: _normalizeClass(["item-tree-index-title-text level is-mobile flex-grow", {'item-tree-index-add-indent': !_ctx.hasIndices && !_ctx.hasContent , replace: _ctx.isModifyPid(`/${_ctx.makeUpdatedPidString(_ctx.item.pid)}`)}]),
                  "aria-label": _ctx.returnIndexTitle(_ctx.item)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.returnIndexTitle(_ctx.item)), 1)
                  ]),
                  _: 1
                }, 8, ["to", "id", "replace", "class", "aria-label"]))
              : (_openBlock(), _createElementBlock("a", {
                  key: 2,
                  href: "#",
                  onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => {_ctx.state.show = !_ctx.state.show; _ctx.expand(_ctx.item)}, ["prevent"])),
                  onKeydown: _cache[6] || (_cache[6] = _withKeys(_withModifiers(($event: any) => {_ctx.state.show = !_ctx.state.show; _ctx.expand(_ctx.item)}, ["prevent"]), ["space","enter"])),
                  class: "item-tree-index-title-text level is-mobile flex-grow",
                  id: _ctx.ariaLabelledbyTargetId,
                  "aria-label": _ctx.item.title && _ctx.item.title.indexOf('0000') == -1 ? _ctx.item.title : _ctx.$t('rightPane.unknown')
                }, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.item.title && _ctx.item.title.indexOf('0000') == -1 ? _ctx.item.title : _ctx.$t('rightPane.unknown')), 1),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.item.count), 1)
                ], 40, _hoisted_2))
          ], 2)),
      _createElementVNode("div", { id: _ctx.ariaControlsTargetId }, [
        _withDirectives(_createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(( _ctx.item.children || _ctx.state.childNode ), (child) => {
            return (_openBlock(), _createBlock(_component_ItemTree, {
              "top-pid-number": _ctx.topPidNumber,
              key: child.id,
              item: child,
              n: _ctx.n + 1
            }, null, 8, ["top-pid-number", "item", "n"]))
          }), 128))
        ], 512), [
          [_vShow, _ctx.state.show]
        ]),
        (_ctx.item.contentsBundles && _ctx.item.contentsBundles.length)
          ? _withDirectives((_openBlock(), _createBlock(_component_ContentsBundleIndex, {
              key: 0,
              item: _ctx.item,
              n: _ctx.n + 1,
              current: _ctx.item.pid === `info:ndljp/pid/${_ctx.route.params.pid}`,
              viewerType: _ctx.viewerType
            }, null, 8, ["item", "n", "current", "viewerType"])), [
              [_vShow, _ctx.state.show]
            ])
          : _createCommentVNode("", true)
      ], 8, _hoisted_5)
    ]),
    _: 1
  }, 8, ["class"]))
}