
import { sortBy, isEmpty } from 'lodash'
import { onMounted, defineComponent, reactive, computed } from 'vue'
import CloserController from '../../molecules/CloserController.vue'
import ContentsBundleIndex from './ContentsBundleIndex.vue'
import { useRoute } from 'vue-router'
import { useRouterExtention } from '@/helpers/hooks/useRouterExtention'
import { useStore } from 'vuex'
import { ContentsBundle } from '@/data/@types/ContentsBundle'
import LoadingView from '@/components/molecules/LoadingView.vue'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'
import { cacheGet } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_API_BASE_URL

export default defineComponent({
  name: 'ItemTree',
  components: {
    CloserController,
    ContentsBundleIndex,
    LoadingView,
  },
  props: {
    topPidNumber: {
      type: String,
      required: false,
    },
    item: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      deafult: false,
    },
    tag: {
      type: String,
      default: 'li',
    },
    n: {
      type: Number,
      default: 1,
    },
    viewerType: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const returnIndexTitle = (item: any) => {
      const meta = item.meta
      if (!item.parent) {
        // 第１階層（親なし）
        return meta?.['0001Dtct'] ? meta['0001Dtct'].join() : ''
      }
      // 第２階層以降（親あり）
      if (item.collections?.includes('A00004')) {
        // 錦絵は タイトルと巻を表示
        if (hasMeta(meta?.['0007Dtct'])) return `${meta['0001Dtct'].join(',')}. ${meta['0007Dtct'].join(',')}`
        return meta?.['0001Dtct'] ? meta['0001Dtct'].join() : ''
      } else if (item.collections?.includes('A00008')) {
        // 古典籍-その他 は巻を表示
        return hasMeta(meta?.['0007Dtct']) ? `${meta['0007Dtct'].join(',')}` : ''
      } else if (item.level === '1') {
        // 巻号レベル
        if (hasMeta(meta?.['0007Dtct'])) {
          if (hasMeta(meta?.['0270Dt'])) {
            if (hasMeta(meta?.['0269Dod'])) return `${meta['0007Dtct'].join(',')} ${meta['0270Dt'].join(',')} ${meta['0269Dod'].join(',')}`
            return `${meta['0007Dtct'].join(',')} ${meta['0270Dt'].join(',')}`
          } else {
            if (hasMeta(meta?.['0269Dod'])) return `${meta['0007Dtct'].join(',')} ${meta['0269Dod'].join(',')}`
            return `${meta['0007Dtct'].join(',')}`
          }
        } else {
          if (hasMeta(meta?.['0270Dt'])) {
            if (hasMeta(meta?.['0269Dod'])) return `${meta['0270Dt'].join(',')} ${meta['0269Dod'].join(',')}`
            return `${meta['0270Dt'].join(',')}`
          } else {
            if (hasMeta(meta?.['0269Dod'])) return `${meta['0269Dod'].join(',')}`
            return ''
          }
        }
      } else {
        return meta?.['0001Dtct'] ? meta['0001Dtct'].join() : ''
      }
    }

    const hasMeta = (meta: Array<string>): boolean => {
      if (!meta || meta[0] === '') return false
      if (meta.length === 0) return false
      return true
    }

    // 目次情報を持っているかの確認
    const hasIndices = computed(() => (props.item.children || props.item.type === 'branch' || (props.item.contentsBundles && props.item.contentsBundles.some((e: any) => e.indices))))
    // コンテンツを持っているかの確認
    const hasContent = computed(() => {
      if (!props.item.contentsBundles) return false
      return props.item.contentsBundles.some((contentsBundle: ContentsBundle) => contentsBundle.contents)
    })
    const route = useRoute()
    const routerExtention = useRouterExtention(route)
    const store = useStore()
    const isProcessing = computed(() => store.getters.tocProcessing)
    const currentItem = computed(() => store.getters.item)

    const ariaControlsTargetId = computed(() => `item-tree-controls-${props.item.pid || props.item.id || props.item.title}`)
    const ariaLabelledbyTargetId = computed(() => `item-tree-labelledby-${props.item.pid || props.item.id || props.item.title}`)

    const state = reactive({
      show: props.show,
      childNode: [] as any[],
    })
    const expand = async (item: any) => {
      const isKn = process.env.VUE_APP_IS_KN === 'TRUE'
      // 集約ノードの最終ノードの場合（=子がない場合）、該当条件のアイテムを取得する
      if (item.count && !item.children) {
        const facetUrl = isKn ? `${BASE_URL}/meta/kn/toc/facet/search/${props.topPidNumber}/${item.title}` : `${BASE_URL}/meta/toc/facet/search/${props.topPidNumber}/${item.title}`
        const results = await cacheGet(facetUrl)
        state.childNode = sortBy(results, 'sortKey').map(item => {
          return {
            ...item,
            current: item.pid === currentItem.value.parent,
          }
        })
      }
      // 集約ノード対象外のアイテムの場合、紐づく子アイテムを取得する
      // 集約ノード対象アイテムの場合、孫アイテムも検索する（＝集約ノードは2階層目までのアイテムを集約する）
      // https://redmine.bigtreetc-dl.com/issues/8669#note-19
      if (isEmpty(item.contentsBundles) && item.pid && !item.children) {
        const pidNumber = item.pid.replace('info:ndljp/pid/', '')
        const childUrl = isKn ? `${BASE_URL}/meta/kn/toc/child/search/${pidNumber}` : `${BASE_URL}/meta/toc/child/search/${pidNumber}`
        const results = await cacheGet(childUrl).catch(() => { /* nop */ })
        state.childNode = sortBy(results, 'sortKey')
      }
    }

    const isModifyPid = routerExtention.isModifyPid

    onMounted(async () => {
      if (props.item.current) {
        await expand(props.item)
        state.show = true
        if (document) {
          const currentLink: HTMLElement | null = document.querySelector('div.item-tree-index-title.current > div.closer-controller')
          currentLink && currentLink.click()
        }
      }
    })

    return {
      ariaControlsTargetId,
      ariaLabelledbyTargetId,
      state,
      returnIndexTitle,
      isProcessing,
      route,
      hasIndices,
      hasContent,
      makeUpdatedPidString,
      expand,
      isModifyPid,
    }
  },
})
